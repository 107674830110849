// 任务内容类型（1：图文   2：视频）
export const MISSION_CONTENT_TYPE = {
  RICH: '1',
  VIDEO: '2',
};
// 任务完成条件（1：观看时长   2：转发）
export const MISSION_CONDITIONS = {
  VIDEO: '1',
  SHARE: '2',
};
//任务类型（数据字典） 营销赋能 工作室 推广佣金 广告红包 区域推广
export const MISSION_TYPE = {
  '1': '营销赋能',
  '2': '工作室',
  '3': '推广佣金',
  '4': '广告红包',
  '5': '区域推广',
};

// 任务状态 0 未完成 1 已完成 2 进行中
export const MISSION_STATUS = {
  WEI_WAN_CHENG: '0',
  YI_WAN_CHENG: '1',
  JIN_XING_ZHONG: '2',
};
const { WEI_WAN_CHENG, YI_WAN_CHENG, JIN_XING_ZHONG } = MISSION_STATUS;
export const MISSION_STATUS_LIST = [
  { id: WEI_WAN_CHENG, name: '未完成' },
  { id: YI_WAN_CHENG, name: '已完成' },
  { id: JIN_XING_ZHONG, name: '进行中' },
];
export const MISSION_STATUS_MAP = [{}, ...MISSION_STATUS_LIST].reduce((map, e) => {
  let { id, name } = e;
  map[id] = name;
  return map;
});

