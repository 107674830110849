import request from '@/plugins/axios';

/**
 * 分页任务列表
 */
export function getMissionListByPage(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/page`,
    method: 'get',
    params,
  });
}

/**
 * 未完成的任务列表
 * getIncompleteList
 */
export function getIncompleteMissionList(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/getIncompleteList`,
    method: 'get',
    params,
  });
}

/**
 * 任务详情 描述 + 内容列表
 */
export function getMissionDetailInfo(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/${params.taskId}`,
    method: 'get',
    params,
  });
}

/**
 * 任务内容
 */
export function getMissionContentInfo(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/getContentInfo/${params.contentId}`,
    method: 'get',
    params,
  });
}

/**
 * 点赞任务
 */
export function setLikeTask(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/likeContent`,
    method: 'get',
    headers: { getAllResponse: true },
    params,
  });
}

/**
 * 完成观看任务
 */
export function finishWatchTask(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/watchTask`,
    method: 'get',
    params,
  });
}

/**
 * 完成分享任务
 */
export function finishForwardTask(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/forwardTask`,
    method: 'get',
    params
  });
}

/**
 * 获取任务分类
 */
export function getTaskType(params = {}) {
  return request({
    url: `school-mobile/hTaskInfo/getTaskType`,
    method: 'get',
    params
  });

}
