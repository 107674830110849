<template>
  <div class="mission-container">

    <van-search
      v-model.trim="searchInput"
      class="search-input-container"
      placeholder="请输入搜索关键词"
      @search="handleOnInputSearch()"
    />

    <!-- 任务状态搜索 -->
    <div class="search-tabs-container">
      <div
        v-for="{ id, name } in MISSION_STATUS_LIST"
        :key="id"
        class="search-tab"
        :class="{ checked: id === searchForm.status }"
        @click="handleOnStatusSearch(id)"
      >
        <span>{{ name }}</span>
      </div>
    </div>


    <page-container
      ref="pageContainer"
      class="home-mission-container"
      :scroll-top.sync="scrollTop"

    >
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
      >
        <template #loading>
          {{ pagination.pageNum < 2 ? '' : '加载中...' }}
        </template>

        <!-- 更多列表加载 -->
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- 任务 -->
          <list-item
            v-for="mission of dataList"
            :class="{ 'is-finish': isFinish(mission) }"
            :key="mission.missionId"
            :value="mission"
            :props="{
                title: 'title',
                imgSrc: 'coverImg',
                tagName: 'taskType',
              }"
            @click="toMissionDetail(mission)"
          >
            <template #tag-name="{ data }">{{ classificationMap[data] }}</template>
            <template #bottom="{ data }">
<!--              <div v-if="!isFinish(data)" class="custom-mission-info">-->
<!--                <van-icon v-if="data.studyCount" name="fire"/>-->
<!--                <span>{{ data.studyCount }}</span>-->
<!--              </div>-->
              <div class="custom-mission-info">
                任务进度: {{ data.finish || 0 }} / {{ data.forwardTime }}
              </div>
              <div v-if="isFinish(data)" class="custom-mission-info">
                <span>完成时间: {{ data.completeTime }}</span>
              </div>
            </template>
          </list-item>
        </van-list>

      </van-pull-refresh>
    </page-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ListItem from '@/components/listItem';
import { getDictType } from '@/api/system';
import { getMissionListByPage, getTaskType } from '@/api/mission';
// import { getMyMissionListTestApi as getMissionListByPage } from '@/test/missionList';
import { MISSION_STATUS, MISSION_STATUS_MAP } from '@/views/mission/const';

export default {
  name: 'mission',
  components: { ListItem },
  data() {
    return {
      container: null,
      scrollTop: 0,

      searchInput: '',
      searchForm: {
        title: '',
        status: MISSION_STATUS.JIN_XING_ZHONG,
      },

      classificationMap: {},

      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.homeMission.refreshing,
    }),
    MISSION_STATUS() {
      return MISSION_STATUS;
    },
    MISSION_STATUS_LIST() {
      let { JIN_XING_ZHONG, YI_WAN_CHENG } = MISSION_STATUS;
      return [
        { id: JIN_XING_ZHONG, name: MISSION_STATUS_MAP[JIN_XING_ZHONG] },
        { id: YI_WAN_CHENG, name: MISSION_STATUS_MAP[YI_WAN_CHENG] },
      ];
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('homeMission/setRefreshing', false);

      Object.assign(this, {
        searchInput: '',
        searchForm: {
          title: '',
          status: MISSION_STATUS.JIN_XING_ZHONG,
        },

        dataList: [],
        pagination: {
          // 分页参数
          pageNum: 0,
          pageSize: 10,
          total: 0,
        },
      });

      let { status } = this.$route.query;
      if (status)
        this.searchForm.status = status;
      this.onRefresh();
    }, { immediate: true });
  },
  mounted() {
    this.container = this.$refs.pageContainer;
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    handleOnInputSearch() {
      this.searchForm.title = this.searchInput;
      this.onRefresh();
    },
    handleOnStatusSearch(status) {
      this.searchForm.status = status;
      this.onRefresh();
    },
    isFinish(mission) {
      return mission.status === MISSION_STATUS.YI_WAN_CHENG;
    },

    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getMissionListByPage({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total)
          this.finished = true;
      });
    },
    onRefresh() {
      // 字典
      getTaskType().then(res => {
        let dicList = Array.isArray(res) ? res : [];
        this.classificationMap = [{}, ...dicList].reduce((map, { value, label }) => {
          map[value] = label;
          return map;
        });
      });

      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    toMissionDetail(mission) {
      let { taskId, userTaskId, taskType, title } = mission;
      // let classTitle = `【${this.classificationMap[taskType]}】${title}`;
      let classTitle = this.classificationMap[taskType];
      this.$router.push({
        path: '/missionDetailList',
        query: { taskId, userTaskId, title: classTitle },
      });
      this.$store.commit('missionDetailList/setRefreshing', true);

      // this.$router.push({
      //   path: '/missionDetail',
      //   query: { taskId: mission.taskId },
      // });
    },
  },

};
</script>

<style lang="less" scoped>
.mission-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .search-input-container {
    height: 44px;
    background-color: #efeff4;
    border-bottom: 1px solid #d6d6db;

    .van-search__content {
      background-color: #fff;
      border: 1px solid #eaeaef;
      border-radius: 4px;

      /deep/ .van-icon.van-icon-search {
        color: #b2b2b2;
      }
    }

    .van-cell {
      line-height: 19px;
    }
  }

  .search-tabs-container {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    height: 55px;
    background-color: #fff;

    .search-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      padding: 0 5px;

      & > span {
        position: relative;
        z-index: 2;
        letter-spacing: 1px;
        font-size: 17px;
        color: #888;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 18px;
        width: 0;
        height: 0;
        border-radius: 0;
        background: rgba(255, 255, 255, 0);
        transition: all .2s;
      }

      &.checked {
        & > span {
          color: #333;
          font-weight: bolder;
        }

        &:after {
          width: 100%;
          height: 5px;
          border-radius: 3px;
          background: #65d27e;
          background: -webkit-linear-gradient(to right, #65d27e, #93e8a5, #c1ffcb);
          background: linear-gradient(to right, #65d27e, #93e8a5, #c1ffcb);
        }
      }
    }
  }


  .home-mission-container {
    top: 99px;
    @padding: 16px;

    .list-item {
      padding-right: @padding;
      padding-left: @padding;

      &.is-finish {
        background: url("../../../public/img/finish.png") 304px 40px no-repeat #fff;
        background-size: 57px auto;
      }

      &:nth-of-type(1) {
        padding-top: 16px;
      }

      & + .list-item {
        position: relative;
        z-index: 1;
        border: none;

        &:before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          right: @padding;
          left: @padding;
          border-top: 1px solid #dedede;

        }
      }

      .mission-btn-container {
        margin: 12px 0 0 0;
        text-align: right;

        .van-button {
          padding: 0 14px;

          & + .van-button {
            margin-left: 14px;
          }
        }
      }
    }

    .custom-mission-info {
      position: relative;
      z-index: 1;
      padding: 11px 0 0 0;
      line-height: 12px;

      .van-icon.van-icon-fire {
        margin-right: 7px;
        font-size: 12px;
        color: #e25951;
      }

      & > span {
        font-size: 12px;
        color: #999;
      }

      .mission-status {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 24px;
        font-size: 14px;
        color: #fff;
        background-color: @primary-color;
        border-radius: 12px;
      }
    }


  }
}

</style>
