<template>
  <div
      class="list-item"
      @click="$emit('click', value)"
  >
    <div class="item-image">
      <van-image
          width="100%"
          height="100%"
          :src="value[keys['imgSrc']]"
          fit="cover"
      />
    </div>
    <div class="item-info-detail">
      <div class="item-title">{{ value[keys['title']] }}</div>
      <p>
        <span class="item-type-tag">
          <slot name="tag-name" :data="value[keys['tagName']]">{{ value[keys['tagName']] }}</slot>
        </span>
      </p>
      <slot name="bottom" :data="value"/>
    </div>
  </div>
</template>

<script>
const DEFAULT_PROPS = () => ({
  title: 'title',
  imgSrc: 'imgSrc',
  tagName: 'tagName',
})
export default {
  name: 'ListItem',
  props: {
    value: {
      default: () => ({}),
      type: Object,
    },
    props: {
      default: DEFAULT_PROPS
    },
  },
  computed: {
    keys() {
      return {
        ...DEFAULT_PROPS(),
        ...this.props,
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list-item {
  display: flex;
  padding: 0 0 16px 0;
  background-color: #fff;

  & + .list-item {
    padding-top: 16px;

    border-top: 1px solid #dedede;
  }

  .item-image {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding-top: 20.408%;
    width: 36.151%;
    height: 0;
    flex-shrink: 0;
    border-radius: 4px;
    overflow: hidden;

    .van-image {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  .item-info-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    padding: 0 0 0 16px;
    overflow: hidden;

    & > .item-title {
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      color: #333;
    }

    & > p {
      margin: 12px 0 0 0;
      font-size: 0;
      color: #777;

      .item-type-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 73px;
        height: 20px;
        font-size: 12px;
        color: #ae7143;
        background-color: #fcf2e8;
        border-radius: 7px;
        border-top-left-radius: 2px;
      }

    }
  }

}
</style>
